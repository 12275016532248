
import './App.css';

import { Pages } from './components/Pages';
import Frontpage from './components/mainpage/Frontpage';
import Indulasa from './components/mainpage/Project/Project1/Indulasa';
import Projects from './components/mainpage/Projects';
import Indulasamini from './components/mainpage/Project/Project1/Indulasamini'
import Vachis from './components/mainpage/Project/Project2/Vachis';

function App() {
  return (
     <>
       <Pages/>
       
     </>
  );
}

export default App;
